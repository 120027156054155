import axios from 'axios'
import { SERVER } from '../constants'

export const getDadata = async ({ query, service }) => {
  try {  
    const { data: { error_code, ...rest } } = await axios({
      method: 'POST',
      data: { query },
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/dadata/suggest2/${service}`
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 2,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}