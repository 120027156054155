import React, { Fragment, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Tooltip } from 'reactstrap'
import { assignTask } from '../../../services/api/tasks'
import { setErrorContent } from '../../../redux/Error/actions'
import { Icon } from '../../../layout'
import { ModalAssignee, ModalDeal } from './../../Modals'
import { plural, formattedDateShort } from '../../../utils'
import {
  adaptive_1200,
  COLOR_VALUES,
  COLOR_VALUES_MIDDLE,
  COLOR_VALUES_LIGHT,
  SHOW_ISSUE_BEFORE,
  IS_LIME,
} from '../../../config'

const useStyles = createUseStyles({
  description: {
    marginTop: 14,
    color: '#4a4a4a',
    fontSize: 15,
    lineHeight: 1.4,
    fontWeight: 500,
    cursor: 'pointer',
    position: 'relative',
    [adaptive_1200]: {
      fontSize: 12,
      '& > span:first-child': {
        display: 'inline',
      },
    },
  },
  info: {
    position: 'absolute',
    top: 22,
    right: 20,
    [adaptive_1200]: {
      marginTop: 24,
      top: 'auto',
      right: 'auto',
      position: 'relative',
    },
  },
  purchaseNumber: {
    color: '#4a4a4a',
    fontSize: 15,
    lineHeight: 1.2,
    fontWeight: 600,
    textAlign: 'right',
    [adaptive_1200]: {
      textAlign: 'left',
    },
    '& > span': {
      fontWeight: 500,
    },
  },
  barWrapper: {
    width: '40%',
    position: 'absolute',
    top: -24,
    right: 0,
    [adaptive_1200]: {
      width: '100%',
      marginTop: 24,
      top: 'auto',
      right: 'auto',
      position: 'relative',
    },
  },
  bar: {
    background: '#F3F3F3',
    width: '100%',
    height: 18,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 16,
    position: 'relative',
    '& > div': {
      minWidth: 50,
      height: 16,
      borderRadius: 'inherit',
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
      '& > div': {
        background: '#fff',
        width: 50,
        textAlign: 'center',
        borderWidth: 2,
        borderStyle: 'solid',
        position: 'absolute',
        top: -3,
        bottom: -3,
        right: 0,
        borderRadius: 'inherit',
        fontSize: 13,
        lineHeight: 1.4,
        fontWeight: 600,
      },
    },
  },
  task: {
    maxWidth: 'calc(100% - 365px)',
    minHeight: 32,
    marginTop: 7,
    [adaptive_1200]: {
      maxWidth: '100%',
      minHeight: 0,
      marginTop: 12,
    },
    '& button': {
      maxWidth: 'calc(100% - 42px)',
      padding: '4px 12px !important',
      display: 'inline-block !important',
      verticalAlign: 'top',
      [adaptive_1200]: {
        maxWidth: '100%',
      },
      '& span': {
        maxWidth: '100%',
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  icon: {
    width: 32,
    height: 32,
    marginLeft: 10,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      fill: '#9b9b9b',
      display: 'block'
    },
    [adaptive_1200]: {
      display: 'none',
    },
  },
  items: {
    marginTop: 13,
  },
  item: {
    minWidth: 50,
    color: '#4a4a4a',
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 600,
    textAlign: 'right',
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive_1200]: {
      textAlign: 'left',
      display: 'block',
    },
    '& + &': {
      marginLeft: 16,
      [adaptive_1200]: {
        marginTop: 8,
        marginLeft: 0,
      },
    },
    '& > span': {
      paddingBottom: 2,
      color: '#bbb',
      fontSize: 11,
      lineHeight: 1.2,
      fontWeight: 600,
      display: 'block',
    },
  },
  agentCompanyName: {

  },
  preapprovalStatus: {

  },
  companyLink: {
    maxWidth: '50%',
    position: 'relative',
    [adaptive_1200]: {
      maxWidth: '100%',
    },
    '& + &': {
      marginTop: 2,
    },
    '&:nth-child(1)': {
      fontSize: 20,
      lineHeight: 1.2,
    },
    '&:nth-child(2)': {
      fontSize: 16,
      lineHeight: 1.5,
    },
    '& > a': {
      maxWidth: '100%',
      color: '#242232',
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
      '&:hover': {
        '& > span': {
          textDecoration: 'underline',
        },
      },
      '& > span': {
        maxWidth: '100%',
        paddingLeft: 28,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
    },
    '& svg': {
      width: 24,
      height: 24,
      fill: '#bbb',
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
  status: {
    marginTop: 8,
  },
  type: {
    background: '#DCDCDC',
    marginRight: 10,
    padding: [2, 7],
    color: '#4a4a4a',
    fontSize: 11,
    lineHeight: 1.5,
    fontWeight: 600,
    borderRadius: 2,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  orderNumber: {
    marginRight: 10,
  },
  createdDate: {
    color: '#9B9B9B',
  },
})

const ICONS = {
  bankguarantee: 'БГ',
  bankguaranteeV2: 'БГ',
  bankguaranteeReissue: 'БГП',
  bankguaranteeArrangeDelivery: 'ОДБГ',
  bankguaranteeClaim: 'БГТ',
  monit: 'М',
  companyRebinding: 'ПК',
  bgLimitCompose: 'БГ',
  bankguaranteeClose: 'БГЗ',
  rko: 'РКО',
  bankguaranteeLimit: 'Л',
  bankguaranteeCommissionChange: 'БГ',
  agentBilling: 'Акт',
  credit: 'КИК',
  agentRegistration: 'РА'
}

export const TaskCard = ({
  tasks,
  phases,
  status,
  orderStatus,
  preapprovalStatus,
  statusItems,
  daysToStart,
  order_id,
  orderNumber,
  createdDate,
  durationDays,
  agentCompany,
  agentLogin,
  agentLogins,
  principalCompany_displayName,
  principalCompany_id,
  principalCompany_INN,
  purchaseAmount,
  contract_max_price,
  bankCommission_amount,
  startDate,
  endDate,
  requiredExecutionDate,
  purchaseNumber,
  onOpenDetail,
  executionPercent,
  ismanager,
  orderTypeRefId,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [openDealModal, setOpenDealModal] = useState(false)
  const [openAssigneeModal, setOpenAssigneeModal] = useState(false)
  const [openAssigneeWithLoginModal, setOpenAssigneeWithLoginModal] = useState(false)
  const [activeTaskId, setActiveTaskId] = useState('')
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipWithLoginOpen, setTooltipWithLoginOpen] = useState(false)

  const typeIcon = ICONS[orderTypeRefId] || '??'

  const isFactoring = orderTypeRefId === 'factoring'

  const hasTasks = tasks?.length > 0

  const isBg = orderTypeRefId.includes('bankguarantee')
  const isCredit = orderTypeRefId.includes('credit')

  const onOpenDealModal = () => setOpenDealModal(true)
  const onCloseDealModal = () => setOpenDealModal(false)

  const onOpenAssigneeModal = (taskId) => {
    setActiveTaskId(taskId)
    setOpenAssigneeModal(true)
  }
  const onOpenAssigneeWithLoginModal = (taskId) => {
    setActiveTaskId(taskId)
    setOpenAssigneeWithLoginModal(true)
  }

  const onCloseAssigneeModal = () => setOpenAssigneeModal(false)
  const onCloseAssigneeWithLoginModal = () => setOpenAssigneeWithLoginModal(false)

  const onMakeTooltip = () => setTooltipOpen(!tooltipOpen)
  const onMakeTooltipWithLogin = () => setTooltipWithLoginOpen(!tooltipWithLoginOpen)
  

  const onShowTaskDetail = (taskId, taskName) => {
    const [task] = tasks

    if (!task.isAssigned) {
      assignTask(taskId).then(res => {
        if (res.isSuccess) {
          onOpenDetail(taskId, taskName)
        } else {
          dispatch(setErrorContent(res.message))
        }
      })
    } else {
      onOpenDetail(taskId, taskName)
    }
  }

  // const renderPhases = () => {
  // 	const phasesText = [
  // 		'Подача заявки',
  // 		'Рассмотрение заявки',
  // 		'Согласование документов',
  // 		'Выдача гарантии',
  // 	]

  // 	const phasesCount = phases.length
  // 	const disabledPhases = 4 - phasesCount

  // 	if (!phasesCount) {
  // 		return null
  // 	}

  // 	return (
  // 		<div className='stages-progress'>
  // 			{phases.map((phase, index) =>
  // 				<div
  // 					key={phase.phaseId}
  // 					className={classnames('stages-progress__item', {
  // 						'stages-progress__item--confirmed': index < phasesCount - 1,
  // 						'stages-progress__item--active': index === phasesCount - 1,
  // 						[`stages-progress__item--${phase.status}`]: true
  // 					})}
  // 				>
  // 					<i className='stages-progress__icon icon icon-ok' />
  // 					<span className='stages-progress__text'>
  // 						{phase.phaseName}
  // 					</span>
  // 				</div>
  // 			)}
  // 			{Array.from({ length: disabledPhases }, (v, i) => i).map(item =>
  // 				<div
  // 					key={item}
  // 					className='stages-progress__item stages-progress__item--disabled'>
  // 					<i className='stages-progress__icon icon icon-ok' />
  // 					<span className='stages-progress__text'>
  // 						{phasesText[phasesCount + item]}
  // 					</span>
  // 				</div>
  // 			)}
  // 		</div>
  // 	)
  // }

  const effectiveStatus = orderStatus
    ? statusItems.filter(({ key }) => key === orderStatus)[0]
      ? orderStatus
      : statusItems.filter(({ orderStatus: os }) => (os || []).filter(({ key }) => key === orderStatus)[0])[0]
        ? statusItems.filter(({ orderStatus: os }) => (os || []).filter(({ key }) => key === orderStatus)[0])[0].key
        : status
    : status

  const renderStatus = () => {
    if (!status && !orderStatus) {
      return null
    }

    if (orderStatus) {
      const currentStatusIndex = statusItems.findIndex(({ key }) => key === effectiveStatus)
      return (
        <div className='stages-progress'>
          {statusItems.slice(currentStatusIndex, currentStatusIndex + 1).map(({
            key,
            className,
            text: defaultText,
          }, index) => {
            if (key === 'total') {
              return null
            }
            const isCurrentStatus = key === effectiveStatus
            let text = defaultText
            if (orderStatus && orderStatus !== effectiveStatus) {
              const currentParent = statusItems.filter(({ key }) => key === effectiveStatus)[0]
              if (currentParent && currentParent.orderStatus) {
                const currentItem = currentParent.orderStatus.filter(({ key }) => key === orderStatus)[0]
                if (currentItem) {
                  text = currentItem.name
                }
              }
            }
            if (isBg || isCredit) {
              if (phases && phases[phases.length - 1]?.phaseName && status !== 'lost' ) {
                text = phases[phases.length - 1].phaseName;
              }
            }

            const renderPastStatuses = currentStatusIndex >= index

            return (
              <div
                key={key}
                className={classnames('stages-progress__item', {
                  'stages-progress__item--disabled': !renderPastStatuses,
                  'stages-progress__item--confirmed': renderPastStatuses && !isCurrentStatus,
                  'stages-progress__item--active': isCurrentStatus,
                  [`stages-progress__item--${className}`]: className,
                })}
                title={text}
              >
                <i className='stages-progress__icon icon icon-ok' />
                <span className='stages-progress__text'>{text}</span>
              </div>
            )
          })}
        </div>
      )
    }
  }

  const renderDaysToStart = () => daysToStart
    ? <div className='block-list__posted-time'>
        <span>{daysToStart}</span>
      </div>
    : null

  // const phasesBlock = renderPhases()
  const daysToStartBlock = renderDaysToStart()
  const statusBlock = renderStatus()

  const companyLink = {
    pathname: '/tasks',
    search: '?view_name=company&view_type=modal&company_id=' + principalCompany_id,
    state: {
      companyId: principalCompany_id,
      isModal: true,
      modalName: 'companyInfo',
    }
  }

  const isIP = principalCompany_displayName.slice(0,2).toLocaleLowerCase() === 'ип'
  const showButtons = IS_LIME && ismanager;
  return (
    <>
      <div className='block-list__item'>
        <div>
          <div className={classes.companyLink}>
            <Link to={companyLink}>
              {isIP
                ? <Icon iconName='user3' />
                : <Icon iconName='company' />
              }
              <span>{principalCompany_displayName}</span>
            </Link>
          </div>
          <div className={classes.companyLink}>
            <Link to={companyLink}>ИНН: {principalCompany_INN}</Link>
          </div>
          <div className={classes.companyLink}>
            {agentCompany && <span className={classes.agentCompanyName}>агент: {agentCompany}</span>}
          </div>
          <div className={classes.task}>
            {preapprovalStatus && <span className={classes.preapprovalStatus}>{preapprovalStatus}</span>}
          </div>
          <div className={classes.task}>
            {hasTasks && tasks.map(({
              task_id,
              name,
              assignee,
              assigneeFullName,
            }, index) => {
              const id = `id-${task_id}`
              const idWithLogin = id + 'login'
              // const isOwnCompanyTask = (agentLogins || []).includes(agentLogin)
              // if(!isOwnCompanyTask) return null;
              return (
                <Fragment key={index}>
                  <button
                    type='button'
                    className='btn btn-primary btn-primary--with-icon'
                    onClick={() => onShowTaskDetail(task_id, name)}
                  >
                    <span>{name}</span>
                    <Icon iconName='arrow-right' />
                  </button>
                  {showButtons &&
                    
                    <div
                      id={id}
                      className={classes.icon}
                      onClick={() => onOpenAssigneeModal(task_id)}
                    >
                      <Icon iconName='user' />
                      {assigneeFullName &&
                        <Tooltip placement='top'
                          isOpen={tooltipOpen}
                          target={id}
                          toggle={onMakeTooltip}
                          fade={false}
                        >
                          {assigneeFullName + (assignee ? ' / ' + assignee : '')}
                        </Tooltip>
                      }
                    </div>
                  }
                  {showButtons && 
                    <div
                      id={idWithLogin}
                      className={classes.icon}
                      onClick={() => onOpenAssigneeWithLoginModal(task_id)}
                    >
                      <Icon iconName='agentLogin'/>
                      {assigneeFullName &&
                        <Tooltip placement='top'
                          isOpen={tooltipWithLoginOpen}
                          target={idWithLogin}
                          toggle={onMakeTooltipWithLogin}
                          fade={false}
                        >
                          {"Переназначить агента по логину"}
                        </Tooltip>
                      }
                    </div>
                  }
                </Fragment>
              )
            })}
          </div>
          <div className={classes.description} onClick={onOpenDealModal}>
            {!isFactoring &&
              <span className={classes.type}>{typeIcon}</span>
            }
            <span className={classes.orderNumber}>{orderNumber}</span>
            <span className={classes.createdDate}>от {createdDate}</span>
            <div className={classes.barWrapper}>
              {executionPercent >= 0 && statusItems.map(({ key, className }, index) => key === effectiveStatus
                ? <div
                    key={index}
                    className={classes.bar}
                    style={{borderColor: COLOR_VALUES_MIDDLE[className]}}
                  >
                    <div style={{
                      width: `${executionPercent}%`,
                      background: COLOR_VALUES_LIGHT[className],
                    }}>
                      <div style={{
                        borderColor: COLOR_VALUES_MIDDLE[className],
                        color: COLOR_VALUES[className],
                        transform: `translateX(${ executionPercent >= 50 ? '1px' : '-1px'})`
                      }}>
                        {executionPercent}%
                      </div>
                    </div>
                  </div>
                : null
              )}
              {!!(statusBlock || daysToStartBlock) &&
                <div className={classes.status}>
                  {statusBlock}
                  {/* {daysToStartBlock} */}
                </div>
              }
            </div>
          </div>
        </div>
        {purchaseNumber &&
          <div className={classes.info}>
            {!!purchaseNumber &&
              <div className={classes.purchaseNumber}>
                <span>Номер торгов:</span>
                {' '}
                {purchaseNumber}
              </div>
            }
            <div className={classes.items}>
              {!!durationDays &&
                <div className={classes.item}>
                  <span>Срок (дней)</span>
                  {durationDays} {plural(durationDays, 'день', 'дня', 'дней')}
                </div>
              }
              {!!startDate &&
                <div className={classes.item}>
                  <span>От</span>
                  {formattedDateShort(startDate)}
                </div>
              }
              {!!endDate &&
                <div className={classes.item}>
                  <span>До</span>
                  {formattedDateShort(endDate)}
                </div>
              }
              {!!contract_max_price &&
                <div className={classes.item}>
                  <span>НМЦ, ₽</span>
                  {contract_max_price}
                </div>
              }
              {!!purchaseAmount &&
                <div className={classes.item}>
                  <span>Сумма, ₽</span>
                  {purchaseAmount}
                </div>
              }
              {!!bankCommission_amount &&
                <div className={classes.item}>
                  <span>Счет:</span>
                  {bankCommission_amount} ₽
                </div>
              }
              {!!requiredExecutionDate && SHOW_ISSUE_BEFORE &&
                <div className={classes.item}>
                  <span>Оформить до:</span>
                  {requiredExecutionDate}
                </div>
              }
            </div>
          </div>
        }
      </div>
      {openDealModal &&
        <ModalDeal 
          width='930px'
          orderId={order_id}
          onClose={onCloseDealModal} 
        />
      }
      {openAssigneeModal &&
        <ModalAssignee
          width='500px'
          height='350px'
          taskId={activeTaskId}
          onClose={onCloseAssigneeModal}
          byLogin={false}
        />
      }
      {openAssigneeWithLoginModal &&
        <ModalAssignee
          width='500px'
          height='350px'
          taskId={activeTaskId}
          onClose={onCloseAssigneeWithLoginModal}
          byLogin={true}
        />
      }
    </>
  )
}

TaskCard.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  createdDate: PropTypes.string,
  durationDays: PropTypes.number,
  principalCompany_displayName: PropTypes.string,
  principalCompany_id: PropTypes.string,
  principalCompany_INN: PropTypes.string,
  purchaseAmount: PropTypes.string,
  contract_max_price: PropTypes.string,
  daysToStart: PropTypes.string,
  phases: PropTypes.array,
  status: PropTypes.string,
  tasks: PropTypes.array,
  onOpenDetail: PropTypes.func.isRequired,
  statusItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      className: PropTypes.string
    })
  )
}

TaskCard.defaultProps = {
  principalCompany_INN: '&mdash',
  purchaseAmount: '&mdash',
  contract_max_price: '&mdash',
  phases: [],
  status: '',
  statusItems: [],
}